



















































import { Component, mixins } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import IconBrainstorming from '@/components/icon/Brainstorming.vue';
import IconBird from '@/components/icon/Bird.vue';
import IconHeartMask from '@/components/icon/HeartMask.vue';
import IconTrees from '@/components/icon/Trees.vue';

import { WidthByUserContextMixin } from '@/mixins/width-by-user-context';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
    IconBrainstorming,
    IconBird,
    IconHeartMask,
    IconTrees,
  },
})
export default class SectionAboutUs extends mixins(WidthByUserContextMixin) {
  animate = false;
  width = 0;
  source = '';

  values = [
    /* eslint-disable max-len */
    {
      icon: IconBrainstorming,
      title: '<span class="t-secondary">Power</span> to All',
      description: 'We run our company together. No really, we do! There are no managers. We encourage self-leadership and initiative. We commit to shaping Theodora Tech together - from the big to the small. What are we doing for Friday’s AW? What should our salaries be? We celebrate our victories as a team. And we learn from our setbacks as a team. We believe it makes us stronger.',
    },
    {
      icon: IconBird,
      title: 'Freedom of <span class="t-secondary">Choice</span>',
      description: 'We are our own most important assets, and so we want the freedom to steer Theodora Tech in a direction that works for us. We want to be able to choose fun and challenging consultancy gigs. We want to be able to explore new startup ideas and have the freedom to choose how to spend our time. And sometimes we just want to have fun. To do this, we reinvest our profit in ourselves instead of having shareholders who want return on their investments.',
    },
    {
      icon: IconHeartMask,
      title: '<span class="t-secondary">Zero</span> Overtime',
      description: 'We have a vision of zero overtime. When we’re at work, we’re a 100% present. And when we’re not, we’re enjoying life with the few other things that are important besides Data. We do this through expectation management with our clients, and we value our work internally within Theodora Tech equally to our billable time. To us, a working week should be no more than 40 hours.',
    },
    {
      icon: IconTrees,
      title: '<span class="t-secondary">Impact</span> on Surroundings',
      description: 'We encourage and support that passion project of yours that makes the world just a little bit better. Theodora Tech has and should have a positive impact on our surroundings - for the people who work here, the people we meet, our clients, our society and the Globe.',
    },
    /* eslint-enable max-len */
  ];

  mounted () {
    this.$nuxt.$once('animateSection:about-us', () => {
      this.animate = true;
    });

    const el = this.$refs.aboutUsContainer as HTMLElement;
    const topTriggerPos = this.$store.state.navigation.scrollTriggerPos;
    ScrollTrigger.create({
      trigger: el,
      start: `top ${topTriggerPos}`,
      end: `bottom ${topTriggerPos}`,
      invalidateOnRefresh: true,
      onToggle: (e) => {
        if (e.isActive)
          this.$store.commit('navigation/setLightTheme', false);
      },
    });

    this.width = typeof (window.visualViewport) === 'undefined' ? window.outerWidth : window.visualViewport.width;
    const userContextWidth = this.widthByUserContext(this.width);
    if (userContextWidth > 1280)
      this.source = '/videos/about-us-1080.mp4';
    else if (userContextWidth > 853)
      this.source = '/videos/about-us-720.mp4';
    else
      this.source = '/videos/about-us-480.mp4';
  }
}
