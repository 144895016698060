




























import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class Disclosure extends Vue {
  @Prop({ type: String, required: true })
    id!: string;

  active = false;

  toggleActive () {
    this.active = !this.active;
  }
}
