import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=7475de8c&"
import script from "./AboutUs.vue?vue&type=script&lang=ts&"
export * from "./AboutUs.vue?vue&type=script&lang=ts&"
import style0 from "./AboutUs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileCarousel: require('/Users/markussh/git/theodora-tech-website/src/components/ProfileCarousel.vue').default,AnimateHeading: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateHeading.vue').default,AnimateFade: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateFade.vue').default,FullWidthVideoLoop: require('/Users/markussh/git/theodora-tech-website/src/components/FullWidthVideoLoop.vue').default,AnimateScrollText: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateScrollText.vue').default,SectionAboutUsOurValuesList: require('/Users/markussh/git/theodora-tech-website/src/components/section/AboutUs/OurValuesList.vue').default,SectionAboutUsOurValuesAccordion: require('/Users/markussh/git/theodora-tech-website/src/components/section/AboutUs/OurValuesAccordion.vue').default,SectionHistory: require('/Users/markussh/git/theodora-tech-website/src/components/section/History/History.vue').default})
