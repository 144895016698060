













import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class FullWidthVideoLoop extends Vue {
  @Prop({ type: String, required: true })
    source!: string;

  videoEl;
  observer;
  visibleHeight = 0;
  overflowHeight = 0;

  mounted () {
    this.videoEl = this.$refs.videoEl as HTMLVideoElement;
    this.observer = new ResizeObserver(this.setOverflowHeight);
    this.observer.observe(this.videoEl);
    this.setOverflowHeight();
    const thisVue = this;

    gsap.to(this.videoEl, {
      scrollTrigger: {
        trigger: this.$refs.container as HTMLElement,
        scrub: true,
        onToggle (e) {
          e.isActive ? thisVue.videoEl.play() : thisVue.videoEl.pause();
        },
      },
      y: -this.overflowHeight,
      ease: 'none',
    });
  }

  beforeDestroy () {
    this.observer.unobserve(this.$refs.container as HTMLElement);
  }

  setOverflowHeight () {
    this.overflowHeight = this.videoEl.clientHeight * 0.2;
    this.visibleHeight = this.videoEl.clientHeight * 0.8;
    ScrollTrigger.refresh();
  }

  get containerHeight () {
    return `height: ${this.visibleHeight}px;`;
  }
}
