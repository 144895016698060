









import { Component, Vue, Prop } from 'nuxt-property-decorator';

import IconBrainstorming from '@/components/icon/Brainstorming.vue';
import IconBird from '@/components/icon/Bird.vue';
import IconHeartMask from '@/components/icon/HeartMask.vue';
import IconTrees from '@/components/icon/Trees.vue';

@Component({
  components: {
    IconBrainstorming,
    IconBird,
    IconHeartMask,
    IconTrees,
  },
})
export default class OurValuesList extends Vue {
  @Prop(Array)
    values!: any[];
}
