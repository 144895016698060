





















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class OurValuesaccordion extends Vue {
  @Prop(Array)
    values!: any[];
}
