import { render, staticRenderFns } from "./OurValuesAccordion.vue?vue&type=template&id=2ed0de24&"
import script from "./OurValuesAccordion.vue?vue&type=script&lang=ts&"
export * from "./OurValuesAccordion.vue?vue&type=script&lang=ts&"
import style0 from "./OurValuesAccordion.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Disclosure: require('/Users/markussh/git/theodora-tech-website/src/components/misc/Disclosure.vue').default})
