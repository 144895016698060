import { Vue, Component } from 'nuxt-property-decorator';

@Component
export class WidthByUserContextMixin extends Vue {
  /*
  ** Check user context (width + dpr + network connection)
  ** Stolen from @danetag :)
  ** https://medium.com/stink-studios/how-we-built-chobani-com-98fe5613b1b5
  */
  widthByUserContext (width, MAX_WIDTH = 2880) {
    if (typeof (window) === 'undefined')
      return width;

    let dpr = window.devicePixelRatio ? window.devicePixelRatio : 1;
    let type = '';

    if (navigator && navigator.connection && navigator.connection.type)
      type = navigator.connection.type;
    if (navigator && navigator.connection && navigator.connection.effectiveType)
      type = navigator.connection.effectiveType;

    // If bad connection, we downgrade dpr to 1
    if (type && (type !== 'wifi' && type !== '4g'))
      dpr = 1;

    width *= dpr;

    if (width > MAX_WIDTH)
      width = MAX_WIDTH;

    return width;
  }
}
