







import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class AnimateScrollText extends Vue {
  el;
  tl;
  textOverflow = 0;

  mounted () {
    this.el = this.$refs.el as HTMLElement;
    this.$nuxt.$on('onResize', this.initAnimation);
    this.initAnimation();
  }

  beforeDestroy () {
    this.$nuxt.$off('onResize', this.initAnimation);
  }

  initAnimation () {
    if (this.tl) {
      this.tl.kill();
      this.tl = null;
    }
    this.textOverflow = this.el.clientWidth - this.$store.state.app.bounds.vw;
    this.tl = gsap.timeline();
    this.tl
      .set(this.el, { clearProps: 'all' })
      .to(this.el, { x: -this.textOverflow, ease: 'none' });
    ScrollTrigger.create({
      animation: this.tl,
      trigger: this.el,
      scrub: true,
    });
  }
}
